
import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api'
import { ConfettiButton } from '~/components/ConfettiButton.vue'
import { ConfettiLogo } from '~/components/ConfettiLogo.vue'
import { joinGame } from '~/utils/api'

export default defineComponent({
  components: {
    ConfettiLogo,
    ConfettiButton,
  },
  setup() {
    const router = useRouter()
    const code = ref<string>()
    const team = ref<string>()
    const isLoading = ref(false)
    const onSubmit = async () => {
      if (!code.value || !team.value) return

      try {
        isLoading.value = true
        const token = await joinGame(code.value, team.value)
        await router.replace({ path: token.code })
      } catch (err) {
        alert(err.response?.data || err.messsage)
      } finally {
        isLoading.value = false
      }
    }

    return {
      maxlength: 5,
      code,
      team,
      onSubmit,
      isLoading,
    }
  },
})
