import * as Sentry from '@sentry/browser'
import axios, { AxiosError } from 'axios'
import { AnswerStatus, Game, GameSummary, Quiz, TokenPayload } from './types'

const api = axios.create({
  baseURL: '/api',
})

api.interceptors.response.use(undefined, (err: AxiosError) => {
  // capture 5XX errors
  if (
    !err.response ||
    (err.response.status >= 500 && err.response.status <= 599)
  )
    Sentry.captureException(err)
  return Promise.reject(err)
})

export const setSocketId = (socketId?: string) => {
  api.defaults.headers['x-socket-id'] = socketId
}

export const getGame = async (code: string) => {
  const { data } = await api.get<Game>(`/game/${code}`)
  return data
}

export const getQuizByGame = async (code: string) => {
  const { data } = await api.get<Quiz>(`/game/${code}/quiz`)
  return data
}

export const getGameSummary = async (code: string) => {
  const { data } = await api.get<GameSummary>(`/game/${code}/summary`)
  return data
}

export const joinGame = async (code: string, team: string) => {
  const { data } = await api.post<TokenPayload>(`/game/${code}/join`, { team })
  return data
}

export const addNextQuestion = async (code: string) => {
  const { data } = await api.post<Game>(`/game/${code}/question`)
  return data
}

export const startQuestion = async (code: string, questionKey: string) => {
  const { data } = await api.post<Game>(
    `/game/${code}/question/${questionKey}/start`,
  )
  return data
}

export const setAnswerStatus = async (
  code: string,
  questionKey: string,
  answerKey: string,
  status: AnswerStatus,
) => {
  const { data } = await api.post<GameSummary>(
    `/game/${code}/question/${questionKey}/answer/${answerKey}/status`,
    { status },
  )
  return data
}

export const setTeamExtraPoints = async (
  code: string,
  teamKey: string,
  extraPoints: number,
) => {
  const { data } = await api.post<GameSummary>(
    `/game/${code}/team/${teamKey}/extra-points`,
    { extraPoints },
  )
  return data
}

export const removeTeamFromGame = async (code: string, teamKey: string) => {
  const { data } = await api.delete<GameSummary>(
    `/game/${code}/team/${teamKey}`,
  )
  return data
}

export const endQuestion = async (code: string, questionKey: string) => {
  const { data } = await api.post<Game>(
    `/game/${code}/question/${questionKey}/end`,
  )
  return data
}

export const submitAnswer = async (
  code: string,
  questionKey: string,
  content: string,
) => {
  const { data } = await api.post<Game>(
    `/game/${code}/question/${questionKey}/answer`,
    { content },
  )
  return data
}
