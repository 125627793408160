
import { defineComponent, h } from '@nuxtjs/composition-api'

export const ConfettiLogo = defineComponent({
  setup() {
    return () =>
      h(
        'div',
        { class: 'logo' },
        Array.from({ length: 3 }, () => h('div', { class: 'circle' })),
      )
  },
})

export default ConfettiLogo
